import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'constants/moment'
import { PURCHASE_PRESCRIPTION_PRICE } from 'constants/config'
import {
  PRESCRIPTION_TYPE_ERECTION_DRUGS,
  PRESCRIPTION_TYPE_HAIRLOSS_DRUGS,
  PRESCRIPTION_TYPE_WEIGHT_DRUGS,
} from 'constants/prescriptions'
import { Block, Button, SvgIcon } from 'components'
import { MainContainer } from 'containers'
import T from 'types'
import { formatPrice } from 'utils/helpers'
import { CookiesHelper } from 'helpers'
import { getIcon } from 'assets/icons'
import prescriptionImage from 'assets/images/prescription-preview.jpg'
import '../styles.sass'

type IProps = {
  collectionPrescription?: any[]
  prescription: T.Prescription
  showPopup: (
    {
      classPopup,
      popupTitle,
      popupType,
      popupProps,
    }: {
      classPopup: string
      popupTitle: string
      popupType: string
      popupProps: any
    },
  ) => void
  profile: T.Profile
}

class DownloadDrugsPrescription extends React.Component<IProps> {

  private renderOrderTreatment() {
    const { prescription } = this.props
    const onlyOption = prescription.canBeDownloaded
    const canBeReleased = prescription.canBeReleased
    const data = [
      <span>Livraison depuis une pharmacie française, dans un colis discret, à domicile ou en point relais en 24 à 48h</span>,
      <span>Suivi de traitement par un assistant du médecin par messages et téléphone</span>,
      <span>Conservation gratuite de votre e-ordonnance sur un serveur sécurisé</span>,
      <span>Rappel automatique d’échéance d’ordonnance</span>,
      <span>30 € offerts sur les Thérapies MASTER, TIME et SOMMEIL</span>,
    ]
    return (
      <Block
        title={
          onlyOption
            ? 'Me faire livrer - OFFERT'
            : 'Option 1 - Me faire livrer - OFFERT'
        }
        classname="treatment option-block"
        icon="clinic.box"
        headerInside
      >
        <div className="treatments-subtitle">
          Faites vous livrer gratuitement, on s’occupe du reste :
        </div>
        <ul className="exit-bullets text-left">
          {data.map((datum, index) => (
            <div key={`exit-bullet_${index}`} className="d-flex align-items-md-center">
              <SvgIcon icon={getIcon('greenCheck')} classname="exit-bullets-icon" />
              <li>{datum}</li>
            </div>
          ))}
        </ul>
        <div className="d-flex justify-content-center">
          {canBeReleased ? (
            <Link className="btn btn-primary" to={`/ordonnance/${prescription.id}`}>
              Me faire livrer
            </Link>
          ) : moment(prescription.expiredAt).diff(moment()) > 0 ? (
            <Button classname="btn btn-primary" disabled={true}>
              Commande maximale mensuelle atteinte
            </Button>
          ) : (
            <Button classname="btn btn-primary" disabled={true}>
              Cette ordonnance est expirée
            </Button>
          )}
        </div>
      </Block>
    )
  }

  renderOrderWeightTreatment() {
    const { prescription } = this.props
    const canBeReleased = prescription.canBeReleased
    const data = [
      <span>Livraison depuis une pharmacie française en 24 à 48h</span>,
      <span>Suivi de traitement par un assistant du médecin par messages et téléphone</span>,
      <span>Rappel automatique d’échéance d’ordonnance</span>,
    ]
    return (
      <Block
        title="Option 1 - Me faire livrer mon traitement"
        classname="weight-treatment option-block"
        icon="clinic.box"
        headerInside
      >
        <ul className="exit-bullets text-left mb-5">
          {data.map((datum, index) => (
            <div key={`exit-bullet_${index}`} className="d-flex align-items-md-center">
              <SvgIcon icon={getIcon('greenCheck')} classname="exit-bullets-icon" />
              <li>{datum}</li>
            </div>
          ))}
        </ul>
        <div className="weight-treatment-subtitle mb-5">
          Bénéficiez gratuitement de notre accompagnement GLP-1
        </div>
        <div className="d-flex justify-content-center">
          {canBeReleased ? (
            <Link className="btn btn-primary" to={`/ordonnance/${prescription.id}`}>
              Je me fais livrer mon traitement
            </Link>
          ) : moment(prescription.expiredAt).diff(moment()) > 0 ? (
            <Button classname="btn btn-primary" disabled={true}>
              Commande maximale mensuelle atteinte
            </Button>
          ) : (
            <Button classname="btn btn-primary" disabled={true}>
              Cette ordonnance est expirée
            </Button>
          )}
        </div>
      </Block>
    )
  }

  renderOrderDownload() {
    const data = [
      <span>Émission et conservation de l'ordonnance et de toutes les suivantes sur un serveur sécurisé</span>,
      <span>Rappel automatique d’échéance d’ordonnance</span>,
      <span>Accès à l’assistance technique par messages et téléphone</span>,
    ]
    return (
      <Block
        title={`Option 2 - Télécharger mon e-ordonnance sans commander`}
        icon="clinic.download"
        headerInside
        classname="option-block"
      >
        <>
          <div className="treatments-subtitle">
            Payer une seule fois {PURCHASE_PRESCRIPTION_PRICE}€ pour obtenir :
          </div>
          <ul className="exit-bullets text-left">
            {data.map((datum, index) => (
              <div key={`exit-bullet_${index}`} className="d-flex align-items-md-center">
                <SvgIcon icon={getIcon('greenCheck')} classname="exit-bullets-icon" />
                <li>{datum}</li>
              </div>
            ))}
          </ul>
          <div className="d-flex justify-content-center">
            <Link className="btn btn-outline-primary" to="/purchase-prescription">
              {`Télécharger l'ordonnance - ${formatPrice(PURCHASE_PRESCRIPTION_PRICE)}`}
            </Link>
          </div>
        </>
      </Block>
    )
  }

  renderOrderDownloadWeight() {
    const { prescription, profile } = this.props
    const actualDownloadURL = `${prescription.downloadUrl
    }?access_token=${CookiesHelper.getPureOauthAccessToken()}`
    return (
      <Block
        title={`Option 2 - Télécharger mon ordonnance`}
        icon="clinic.download"
        headerInside
        classname="option-block"
      >
        <>
          <div className="d-flex justify-content-center">
            {profile.bodyResetProgramLastPurchasedAt
              ? (
                <a href={actualDownloadURL} target="_blank" className="btn btn-outline-primary">
                  Télécharger l'ordonnance
                </a>)
              : (
                <Link className="btn btn-primary" to="/weight-drugs-prescription/recommendation">
                  Télécharger l'ordonnance
                </Link>
              )}
          </div>
        </>
      </Block>
    )
  }

  renderPostalOption() {
    const data = [<span>Réception de votre ordonnance par la poste en 5 jours ouvrés</span>]
    return (
      <Block
        title="Option 3 - Recevoir mon ordonnance par la poste"
        icon="clinic.mail"
        headerInside
        classname="option-block"
      >
        <div className="treatments-subtitle">
          Recevez votre ordonnance par la poste pour l’utiliser où vous le souhaitez :
        </div>
        <ul className="exit-bullets text-left">
          {data.map((datum, index) => (
            <div key={`exit-bullet_${index}`} className="d-flex align-items-md-center">
              <SvgIcon icon={getIcon('greenCheck')} classname="exit-bullets-icon" />
              <li>{datum}</li>
            </div>
          ))}
        </ul>
        <div className="d-flex justify-content-center">
          <Link className="btn btn-outline-primary" to="/deliver-prescription">
            Me faire livrer mon ordonnance
          </Link>
        </div>
      </Block>
    )
  }

  showPrescriptionPopup = () => {
    const { showPopup, prescription } = this.props
    showPopup({
      classPopup: '',
      popupTitle: 'Mon ordonnance',
      popupType: 'POPUP_PRESCRIPTION',
      popupProps: {
        prescription,
      },
    })
  }
  render() {
    const { prescription } = this.props
    // tslint:disable-next-line: max-line-length
    const actualDownloadURL = `${prescription.downloadUrl
      }?access_token=${CookiesHelper.getPureOauthAccessToken()}`
    const isWeightPrescription = prescription.prescriptionType === PRESCRIPTION_TYPE_WEIGHT_DRUGS

    return (
      <MainContainer>
        <div className="page download-prescription">
          <div className="home-container">
            <div className="infos-container">
              <div className="h3">Mon ordonnance</div>
                {prescription.prescriptionType !== PRESCRIPTION_TYPE_WEIGHT_DRUGS && (
                  <div className="check-presc-container mb-5 row">
                    <div className="thumbnail-container col-md-6">
                      <img
                        className="presc-thumbnail img-fluid"
                        src={prescriptionImage}
                        alt="prescription"
                        title="patient"
                      />
                    </div>
                    <div className="details-container col-md-6">
                      <div className="details-wording pt-3">
                        <div className="d-flex">
                          <SvgIcon icon={getIcon('clinic.warning')} classname="icon-24px mr-2" />
                          {this.renderWarning()}
                        </div>
                      </div>
                      {prescription.canBeDownloaded ? (
                        <a href={actualDownloadURL} target="_blank" className="btn btn-outline-primary">
                          Voir mon ordonnance
                        </a>
                        ) : (
                        <Button onClick={() => this.showPrescriptionPopup()} variant="btn-outline-primary">
                          Aperçu ordonnance
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              {isWeightPrescription ? (
                <>
                  {this.renderOrderWeightTreatment()}
                  {this.renderOrderDownloadWeight()}
                </>
              ) : (
                <>
                  {this.renderOrderTreatment()}
                  {!prescription.canBeDownloaded && this.renderOrderDownload()}
                </>
              )}
              {!prescription.wasDelivered &&
                !prescription.canBeDownloaded &&
                this.renderPostalOption()}
            </div>
          </div>
        </div>
      </MainContainer>
    )
  }

  renderWarning = () => {
    const { prescription } = this.props
    switch(prescription.prescriptionType){
      case PRESCRIPTION_TYPE_ERECTION_DRUGS:
        return <span>Les traitements contre les troubles sexuels ne sont pas remboursés par la Sécurité Sociale</span>
      case PRESCRIPTION_TYPE_HAIRLOSS_DRUGS:
        return <span>Les traitements contre la chute de cheveux ne sont pas remboursés par la Sécurité Sociale</span>
      case PRESCRIPTION_TYPE_WEIGHT_DRUGS:
        return <span>les traitements pour la perte de poids ne sont pas remboursés par la Sécurité Sociale</span>
      default:
        return null
    }
  }
}

export default DownloadDrugsPrescription
